import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
} from "reactstrap";
import { Badges, Btn, H4, Ribbon } from "../../../AbstractElements";
import SvgIcon from "../Component/SvgIcon";
import CountUp from "react-countup";
import { Info } from "react-feather";
import { toast } from "react-toastify";
import Request from "../../../api/httpClient";

const Widgets1 = ({ data, setWidgetActivo, tipoInfo }) => {
  const [popover, setPopover] = useState(false);
  const Toggle = () => setPopover(!popover);
  const [loadingWidgetDetails, setLoadingWidgetDetails] = useState(false);
  const [detalles, setDetalles] = useState([]);
  const CustomLoader = () => (
    <div className="loader-box" style={{ height: "2px", padding: "10px" }}>
      <div className="loader-15" />
    </div>
  );
  const request = new Request();

  //obtener los detalles  del widget seleccionado
  const getWidgetDetails = async () => {
    if (loadingWidgetDetails === false) {
      setLoadingWidgetDetails(true);
      let sucursalInfo = null;

      if (JSON.parse(localStorage.sucursal_selec) !== null) {
        if (localStorage.getItem("SucursalesInfo") == 0) {
          sucursalInfo = +JSON.parse(localStorage.sucursal_selec).value;
        }
      }

      let dataStruct = {
        tipo: tipoInfo,
        grupo: data.title.substring(0, 3).toUpperCase(),
        id_usuario: localStorage.getItem("user_id") || 0,
        id_sucursal: sucursalInfo,
        id_empleado:
          JSON.parse(localStorage.empleado_selec) !== null
            ? +JSON.parse(localStorage.empleado_selec).value
            : null,
      };

      const response = await request.post(
        "/users/get/detail/widget",
        dataStruct
      );
      if (response && !response.error) {
        if (response.detalles && !response.empty) {
          console.log(response.detalles);
          setDetalles(response.detalles);
        } else {
          toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        }
      } else {
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
      setLoadingWidgetDetails(false);
    }
  };

  const AsignFontColor = (item) => {
    const colorMap = new Map([
      ["VENTASADOMICILIO", "#54ba4a"],
      ["LOLOGRASTE", "#f73164"],
      ["TE FALTAN", "#f73164"],
    ]);
    const variable = item.nombre.toUpperCase().replace(/\s/g, "");
    if (colorMap.has(variable)) {
      return colorMap.get(variable);
    }
    return "inherit";
  };

  return (
    <Card className="widget-1">
      {data.title !== "..." && (
        <div className="ribbon-wrapper-right">
          <Ribbon
            attrRibbons={{
              className: "ribbon ribbon-clip-right ribbon-right ribbon-primary",
            }}
          >
            <div
              id={"Popover-" + data.title}
              style={{
                visibility:
                  data.title !== "Ranking" /* && data.title !== "Comisiones" */
                    ? "visible"
                    : "hidden",
              }}
            >
              <Info
                width={"20px"}
                style={{ paddingTop: "5px" }}
                onMouseEnter={() => {
                  console.log("mouse encima de mi");
                  setLoadingWidgetDetails(true);
                  getWidgetDetails();
                }}
              />
            </div>
          </Ribbon>

          <Popover
            placement="right"
            isOpen={popover}
            target={"Popover-" + data.title}
            offset={[20, 20]}
            toggle={Toggle}
            trigger="hover"
            style={{ width: "250px" }}
          >
            <PopoverHeader>{data.title}</PopoverHeader>
            <PopoverBody>
              {loadingWidgetDetails ? (
                <CustomLoader />
              ) : (
                detalles
                  .filter((item) => item.nombre !== "")
                  .map((item, index) => (
                    <Row className="details" style={{ minWidth: "150px" }}>
                      <div className="col-9">
                        <div>
                          <span
                            className="f-w-600"
                            style={{
                              color: AsignFontColor(item),
                            }}
                          >
                            {item.nombre}
                          </span>
                        </div>
                      </div>
                      <div className="col-3">
                        <div
                          className="font-primary"
                          style={{ textAlign: "right" }}
                        >
                          <span
                            className="f-w-600"
                            style={{
                              color: AsignFontColor(item),
                            }}
                          >
                            {item.cantidad}
                          </span>
                        </div>
                      </div>
                    </Row>
                  ))
              )}
            </PopoverBody>
          </Popover>
        </div>
      )}
      <CardBody>
        <Btn
          attrBtn={{
            className: "active",
            color: "transparent",
            style: { borderColor: "transparent" },
            onClick: () => {
              data.title !== "Ranking" &&
                data.title !== "Comisiones" &&
                setWidgetActivo(data.title);
            },
          }}
        >
          <div className="widget-content">
            <div className={`widget-round ${data.color}`}>
              <div className="bg-round">
                <SvgIcon className="svg-fill" iconId={`${data.icon}`} />
                <SvgIcon className="half-circle svg-fill" iconId="halfcircle" />
              </div>
            </div>
            <div>
              <H4>
                <CountUp end={data.total} duration={5} />
              </H4>
              <span className="f-light">{data.title}</span>
            </div>
          </div>
        </Btn>
      </CardBody>
    </Card>
  );
};

export default Widgets1;
