import React, { Fragment, useEffect, useState } from "react";
import { Btn } from "../../AbstractElements";
//import { AddSale } from "../../Constant";
import SalesModalForm from "./SalesModalForm";

const SalesModalButton = ({ btnText, title, type, reload, userTypeID }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    console.log("modal:" + modal);
  }, [modal]);

  return (
    <Fragment>
      <Btn
        attrBtn={{
          style: {
            width: 155,
          },
          color: "primary",
          onClick: toggle,
        }}
      >
        {btnText}
      </Btn>
      <SalesModalForm
        modal={modal}
        title={title}
        toggle={toggle}
        size="lg"
        type={type}
        reload={reload}
        userTypeID={userTypeID}
      />
    </Fragment>
  );
};

export default SalesModalButton;
