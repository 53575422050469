import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, H6, P, Image } from "../../../AbstractElements";
import logoWhite from "../../../assets/images/logo/logo.png";
import logoDark from "../../../assets/images/logo/logo_dark.png";
import OVD_Logo from "../../../assets/images/login/ovd_128.svg";
import { toast } from "react-toastify";
import Request from "../../../api/httpClient";
const request = new Request();

const ForgetPwd = ({ logoClassMain }) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [username, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  const sendUser = async (e) => {
    e.preventDefault();
    if (username !== "") {
      setLoading(true);
      let data = { usuario: username };

      const response = await request.post("/users/recover/password", data);

      if (response.usuario) {
        console.log(response.usuario);
        setSendEmail(true);
        toast.success(
          "Se le ha enviado un correo electrónico con las instrucciones para el cambio de contraseña.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 6000,
          }
        );
      } else {
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <section>
        <Container className="p-0 login-page" fluid={true}>
          <Row className="m-0">
            <Col className="p-0">
              <div className="login-card">
                <div>
                  <div>
                    <Link
                      className={`logo ${logoClassMain ? logoClassMain : ""}`}
                      to={process.env.PUBLIC_URL}
                    >
                      <Image
                        attrImage={{
                          className: "img-fluid for-light",
                          src: OVD_Logo,
                          alt: "ovd logo",
                        }}
                      />
                    </Link>
                  </div>
                  <div className="login-main">
                    <Form className="theme-form login-form" onSubmit={sendUser}>
                      <H4>Restablecer contraseña</H4>
                      <FormGroup>
                        <Label className="m-0 col-form-label">
                          Ingrese su usuario
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          required
                          onChange={(e) => setUserName(e.target.value)}
                          value={username}
                          disabled={sendEmail}
                        />
                      </FormGroup>
                      {!sendEmail && (
                        <FormGroup className="text-end">
                          <Btn
                            attrBtn={{
                              className: "btn-block ",
                              color: "primary",
                              type: "submit",
                            }}
                          >
                            Enviar
                          </Btn>
                        </FormGroup>
                      )}
                      {sendEmail && (
                        <FormGroup className="mb-4 mt-4">
                          <span className="reset-password-link">
                            ¿No ha recibido el correo?  
                            <a
                              className="btn-link text-danger"
                              href="#resendEmail"
                              onClick={(e) => sendUser(e)}
                            >
                              Reenviar
                            </a>
                          </span>
                        </FormGroup>
                      )}
                      <P attrPara={{ className: "text-start mt-4" }}>
                        ¿Ya tienes una contraseña?
                        <a className="ms-2" href="/login">
                          Iniciar sesión
                        </a>
                      </P>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default ForgetPwd;
