import React, { Fragment } from "react";
import { Card, CardBody, Col } from "reactstrap";
import { MixedChart } from "../../../Constant";
import HeaderCard from "../../Common/Component/HeaderCard";
import Chart from "react-apexcharts";
import { apexMixedCharts } from "./apexData";

const MixedChartClass = ({ titulo, ventasBalance, loadingWidgetInfo }) => {
  const CustomLoader = () => (
    <div className="loader-box" style={{ height: "2px", padding: "10px" }}>
      <div className="loader-15" />
    </div>
  );

  return (
    <Fragment>
      <Col xxl="8" lg="12" className="box-col-12">
        <Card>
          <div className="header-top">
            <div className="card-header-right-icon">
              {loadingWidgetInfo ? <CustomLoader /> : ""}
            </div>
          </div>
          <HeaderCard title={`Análisis de ${titulo} por Tienda`} />
          <CardBody>
            <div id="mixedchart">
              <Chart
                options={apexMixedCharts.options}
                series={ventasBalance}
                height={350}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default MixedChartClass;
