import React, { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import { Image } from "../../../AbstractElements";
import LoginForm from "./LoginForm";
import OVDLogo from "../../../assets/images/login/ovd_256.svg";

const LoginOne = () => {
  return (
    <Fragment>
      <section>
        <Container fluid={true}>
          <Row>
            <Col
              xl="5"
              className="b-center bg-size "
              style={{
                backgroundImage: `url(${OVDLogo})`,
                backgroundSize: "512px",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                display: "block",
              }}
            >
              <Image
                attrImage={{
                  className: "bg-img-cover bg-center d-none",
                  src: `${require("../../../assets/images/login/3.jpg")}`,
                  alt: "looginpage",
                }}
              />
            </Col>
            <Col xl="7 p-0">
              <LoginForm logoClassMain="text-start" />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default LoginOne;
