import React, { Fragment, useContext, useEffect, useState } from "react";
import { Btn, H4, P, H6, Image, H1 } from "../../../AbstractElements";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import CustomizerContext from "../../../_helper/Customizer";
import { ForgotPassword, RememberPassword, SignIn } from "../../../Constant";
import Request from "../../../api/httpClient";
import { toast } from "react-toastify";
const request = new Request();

const LoginForm = ({ logoClassMain }) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [username, setUserName] = useState(
    localStorage.getItem("username") || ""
  );
  const [password, setPassword] = useState(
    localStorage.getItem("password") || ""
  );
  const [user_id, setUserId] = useState(localStorage.getItem("user_id") || 0);
  const [name, setName] = useState(localStorage.getItem("Name"));
  const [type, setType] = useState(localStorage.getItem("Type"));
  const [typeID, setTypeID] = useState(localStorage.getItem("TypeID"));
  const [sucursales, setSucursales] = useState(
    localStorage.getItem("Sucursales")
  );
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("RememberPassword")
  );

  useEffect(() => {
    localStorage.setItem("user_id", user_id);
    localStorage.setItem("Name", name);
    localStorage.setItem("Type", type);
    localStorage.setItem("TypeID", typeID);
    localStorage.setItem("RememberPassword", rememberMe);
  }, [user_id, name, type, typeID]);

  const loginAuth = async (e) => {
    e.preventDefault();
    if (username !== "" && password !== "") {
      setLoading(true);
      let data = { user: username, password: btoa(password) };

      const response = await request.post("/users/login", data);

      if (response.user) {
        //localStorage.removeItem('aW50ZW50b3M=');
        //context.login({ id: response.user, auth: true, fecha: moment().format() });
        setUserId(response.user.Id);
        setName(response.user.Nombre);
        setType(
          response.user.Tipo != "Agente" ? response.user.Tipo : "Ejecutivo"
        );
        setTypeID(response.user.Id_Perfil);
        // console.log(response.user.Sucursales);
        localStorage.setItem("login", JSON.stringify(true));
        //guardo la hora en que se hizo el login
        localStorage.setItem("horaLogin", new Date().getTime());
        if (rememberMe) {
          // Guarda el token en el almacenamiento local
          localStorage.setItem("password", password);
          localStorage.setItem("username", username);
          localStorage.setItem("RememberPassword", true);
        } else {
          // elimina los token
          localStorage.removeItem("password");
          localStorage.removeItem("username");
          localStorage.setItem("RememberPassword", false);
        }
        ObtenerSucursales(response.user.Sucursales);
      } else {
        //setMessage(response.message);
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
      setLoading(false);
    }
  };

  const ObtenerSucursales = async (sucursales_id) => {
    const response = await request.get("/sucursales/get/all");

    if (response.sucursales) {
      const sucursales_usuario = response.sucursales.filter((obj) => {
        return (
          sucursales_id.split(",").indexOf(obj.Id_sucursal.toString()) !== -1
        );
      });
      const sucursales = sucursales_usuario.map((sucursal) => ({
        value: sucursal.Id_sucursal,
        label: sucursal.Alias,
      }));
      setSucursales(sucursales);
      localStorage.setItem("sucursales", JSON.stringify(sucursales));
      localStorage.setItem("sucursal_selec", JSON.stringify(sucursales[0]));
      localStorage.setItem("empleado_selec", null);
      //voy a la pagina principal
      history(`/dashboard/default/${layoutURL}`);
    } else {
      //setMessage(response.message);
      toast.error(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }
    setLoading(false);
  };

  return (
    <Fragment>
      <div className="login-card">
        <div>
          <div>
            <Link
              className={`logo ${logoClassMain ? logoClassMain : ""}`}
              to={process.env.PUBLIC_URL}
            >
              {/* <Image
                attrImage={{
                  className: "img-fluid for-light",
                  src: logoWhite,
                  alt: "looginpage",
                }}
              />
              <Image
                attrImage={{
                  className: "img-fluid for-dark",
                  src: logoDark,
                  alt: "looginpage",
                }}
              /> */}
              <H4>Operadora Voz y Datos</H4>
            </Link>
          </div>

          <div className="login-main">
            <Form className="theme-form login-form">
              <H4>Iniciar sesión</H4>
              <P>
                Ingrese su correo electrónico y contraseña para iniciar sesión
              </P>
              <FormGroup>
                <Label className="col-form-label m-0">Usuario</Label>
                <Input
                  className="form-control"
                  type="text"
                  required
                  //placeholder="Test@gmail.com"
                  onChange={(e) => setUserName(e.target.value)}
                  value={username}
                />
              </FormGroup>
              <FormGroup className="position-relative">
                <Label className="col-form-label m-0">Contraseña</Label>
                <div className="position-relative">
                  <Input
                    className="form-control"
                    type={togglePassword ? "text" : "password"}
                    name="login[password]"
                    required
                    // placeholder="*********"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                  <div
                    className="show-hide"
                    onClick={() => setTogglePassword(!togglePassword)}
                  ></div>
                </div>
              </FormGroup>
              <FormGroup className="position-relative">
                <div className="checkbox">
                  <Input
                    id="remember-me"
                    type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <Label className="text-muted" for="remember-me">
                    {RememberPassword}
                  </Label>
                </div>
                <Link
                  className="link"
                  to={`/pages/authentication/forget-pwd/Dubai`}
                >
                  {ForgotPassword}
                </Link>
              </FormGroup>
              <FormGroup>
                {loading ? (
                  <div className="loader-box" style={{ height: "20px" }}>
                    <div className="loader-15" />
                  </div>
                ) : (
                  <Btn
                    attrBtn={{
                      className: "d-block w-100 mt-2",
                      color: "primary",
                      type: "submit",
                      onClick: (e) => loginAuth(e),
                    }}
                  >
                    {SignIn}
                  </Btn>
                )}
              </FormGroup>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginForm;
