import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { H5, UL, LI } from "../../../AbstractElements";
import { Earning, Expense, OverallBalanceTitle } from "../../../Constant";
import LightCard from "./LitghtCard";
import ReactApexChart from "react-apexcharts";
import { CurrencyChartData } from "../../../Data/DefaultDashboard/Chart";
import { LightCardData } from "../../../Data/DefaultDashboard";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import "../../../assets/scss/react-contextmenu.css";
const OverallBalance = ({
  ventas,
  titulo,
  loadingWidgetInfo,
  setWidgetActivo,
}) => {
  const CustomLoader = () => (
    <div className="loader-box" style={{ height: "2px", padding: "10px" }}>
      <div className="loader-15" />
    </div>
  );
  const handleClick = (event, data) => {
    //console.log(`clicked`, { event, data });
    setWidgetActivo("Ventas");
  };

  return (
    <Col xxl="8" lg="12" className="box-col-12">
      <Card>
        <div className="header-top">
          <div className="card-header-right-icon">
            {loadingWidgetInfo ? <CustomLoader /> : ""}
          </div>
        </div>
        <CardHeader className="card-no-border">
          <H5>{titulo ? `${titulo} mensuales` : ""} Tienda / Ejecutivo</H5>
        </CardHeader>
        <CardBody className="pt-0">
          <Row className="m-0 overall-card">
            <Col xl="12" md="12" sm="12" className="p-0">
              <div className="chart-right">
                <Row>
                  <Col xl="12" className="col-xl-12">
                    <CardBody className="p-0">
                      <UL
                        attrUL={{
                          horizontal: true,
                          className: "d-flex balance-data",
                        }}
                      >
                        <LI>
                          <span
                            className="circle"
                            style={{ backgroundColor: "#AAAFCB" }}
                          >
                            {" "}
                          </span>
                          <span className="f-light ms-1">Tienda</span>
                        </LI>
                        <LI>
                          <span className="circle bg-primary"> </span>
                          <span className="f-light ms-1">Ejecutivo</span>
                        </LI>
                      </UL>
                      <div className="current-sale-container">
                        <ContextMenuTrigger id="chart-context-menu">
                          <ReactApexChart
                            type="bar"
                            height={300}
                            options={CurrencyChartData.options}
                            series={ventas}
                          />
                          <ContextMenu id="chart-context-menu">
                            <MenuItem
                              data={{ action: "ver" }}
                              onClick={handleClick}
                            >
                              Ver ventas mensuales
                            </MenuItem>
                          </ContextMenu>
                        </ContextMenuTrigger>
                      </div>
                    </CardBody>
                  </Col>
                </Row>
              </div>
            </Col>
            {/* <LightCard LightCardData={LightCardData} /> */}
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default OverallBalance;
