import React, { Fragment, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CommonModal from "../UiKits/Modals/common/modal";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Btn, H6 } from "../../AbstractElements";
import Request from "../../api/httpClient";
import Select from "react-select";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";

registerLocale("es", es);

const SalesModalFormView = ({
  modal,
  title,
  toggle,
  size,
  type,
  reload,
  venta,
}) => {
  const [planes, setPlanes] = useState([]);
  const [plazos, setPlazos] = useState([]);

  const [opciones, setOpciones] = useState([]);
  const [metodosVenta, setMetodosVenta] = useState([]);

  const [fecha, setFecha] = useState(new Date(venta.Fecha));
  const [co_id, setCo_Id] = useState(venta.CodID);
  const [co_id_confirm, setCo_Id_Confirm] = useState("");
  const [comentarios, setComentarios] = useState(venta.Comentarios);
  const [producto, setProducto] = useState("");
  const [plan, setPlan] = useState("");
  const [plazo, setPlazo] = useState("");
  const [metodoVenta, setMetodoVenta] = useState("");
  const [recarga, setRecarga] = useState("");

  const [control, setControl] = useState(venta.oControl);
  const [seguro, setSeguro] = useState(venta.oSeguro);
  const [noches, setNoches] = useState(venta.oNoches);
  const [nyf, setNYF] = useState(venta.oNYF);
  const [hbo, setHBO] = useState(venta.oHBO);
  const [domicilio, setDomicilio] = useState(venta.oEnvioDomicilio);
  const [proBussines, setProBussines] = useState(venta.oProBussines);
  const [multirenta, setMultirenta] = useState(venta.oDtoMultiRenta);
  const [monto, setMonto] = useState(venta.oMonto);
  const [showMonto, setShowMonto] = useState(false);

  const [productos, setProductos] = useState([]);
  const [loadingProductos, setLoadingProductos] = useState(false);
  const request = new Request();

  //obtener todas los productos de un tipo
  const getProductos = async () => {
    setProductos([]);
    setLoadingProductos(true);

    let data = {
      tipo: type,
    };

    const response = await request.post("/ventas/productos/get/type/", data);
    if (response && !response.error) {
      if (response.productos && !response.empty) {
        console.log(response.productos);
        const productos_formateados = FormatearProductos(response.productos);
        setProductos(productos_formateados);
        //asignar el producto correspondiente segun la venta elegida en tabla
        const producto_venta = productos_formateados.find(
          (producto) =>
            producto.id_planes.includes(venta.Id_Marca) &&
            producto.value == venta.Id_Fabricante
        );
        setProducto(producto_venta);
        ActualizarDatos(producto_venta);
      } else {
        setProductos([]);
        setLoadingProductos(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingProductos(false);
  };

  useEffect(() => {
    if (modal) {
      console.log("productos");
      getProductos();
    }
  }, [modal]);

  //cambiar el formato del arreglo de productos que recibo para poder utilizarlo en los componentes de la UI
  const FormatearProductos = (productos) => {
    const productos_formateados = productos.flatMap((obj) =>
      Object.values(obj).map((val) => ({
        value: val.Id_Clasificacion,
        label: val.Clasificacion,
        id_planes: val.Id_Plan,
        planes: val.Plan,
        seleccion: val.Seleccion,
        valor: val.valor,
      }))
    );
    console.log(productos);
    return productos_formateados;
  };

  //limpiar checkoxes de opciones
  const LimpiarDatos = () => {
    //inputs
    setCo_Id("");
    setCo_Id_Confirm("");
    setMultirenta(-1);
    setMonto(0);
    setShowMonto(false);
    setComentarios("");

    //comboboxes
    setPlanes([]);
    setPlazos([]);

    //valores seleccionados en los comboboxes
    setPlan("");
    setPlazo("");
    setRecarga("");
    setMetodosVenta("");

    //opciones - checboxes
    setControl(false);
    setSeguro(false);
    setNoches(false);
    setNYF(false);
    setHBO(false);
    setDomicilio(false);
    setProBussines(false);
  };

  //obtener los datos de la ventana segun el producto seleccionado
  const ActualizarDatos = (producto) => {
    setProducto(producto);

    //planes
    const planes_array = Array.from(new Set(producto.planes.split(", ")));
    const planes_id_array = Array.from(new Set(producto.id_planes.split(", ")));
    const planes = [];

    for (let i = 0; i < planes_array.length; i++) {
      planes.push({
        value: planes_id_array[i],
        label: planes_array[i],
      });
    }
    setPlanes(planes);
    const temp = planes.find((plan) => plan.value == venta.Id_Marca);
    setPlan(temp);
    if (temp && temp.label == "OTRO MONTO") {
      setShowMonto(true);
    }
    let seleccion_array = [];
    let valores_array = [];

    //opciones
    if (producto.seleccion !== null) {
      seleccion_array = Array.from(
        new Set(producto.seleccion.toString().split(", "))
      );
    }
    if (producto.valor !== null) {
      /* valores_array = Array.from(
        new Set(producto.valor.toString().split(", "))
      );*/
      valores_array = [
        ...new Set(
          producto.valor
            .toString()
            .split(", ")
            .map((valor) => valor.replace(/\s/g, ""))
        ),
      ];
    }

    if (seleccion_array.includes("PLAZO")) {
      const plazos = valores_array
        .filter((valor) => valor.includes("Meses"))
        .map((val) => ({
          value: val,
          label: val,
        }));
      setPlazos(plazos);
      setOpciones([]);
    }

    if (seleccion_array.includes("METODO DE VENTA")) {
      const metodos_ventas = valores_array
        .filter((valor) => !valor.includes("Meses") && !valor.includes("SI"))
        .map((val) => ({
          value: val,
          label: val,
        }));
      setMetodosVenta(metodos_ventas);
    }

    if (seleccion_array.includes("DTO MULTI RENTA")) {
      setMultirenta(0);
    } else {
      setMultirenta(-1);
    }

    if (!seleccion_array.includes("PLAZO")) {
      setPlazos([]);
    }
    if (!seleccion_array.includes("METODO DE VENTA")) {
      setMetodosVenta([]);
    }

    setOpciones(seleccion_array);
  };

  return (
    <CommonModal isOpen={modal} title={title} toggler={toggle} size={size}>
      <Modal isOpen={modal} toggle={toggle} size={size} centered>
        <Form>
          <ModalHeader toggle={toggle}>{title}</ModalHeader>
          <ModalBody style={{ paddingTop: "2px", paddingBottom: "2px" }}>
            <Row>
              {type != 3 ? (
                <Fragment>
                  <Col sm="12" md="8">
                    <div
                      className="project-box"
                      style={{ paddingBottom: "5px" }}
                    >
                      {/* <H6>Identificación</H6> */}
                      <span className="badge badge-primary">
                        Identificación
                      </span>
                      <div>
                        <div>
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label text-start">
                              Fecha:
                            </Label>
                            <Col xl="6" sm="9">
                              <DatePicker
                                className="form-control digits"
                                showPopperArrow={true}
                                selected={fecha}
                                dateFormat="dd/MM/yyyy"
                                locale="es"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label text-start">
                              CO_ID:
                            </Label>
                            <Col xl="6" sm="9">
                              <Input
                                id="co_id"
                                type="text"
                                value={co_id}
                                placeholder="CO_ID... "
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label text-start">
                              Producto:
                            </Label>

                            <Col xl="6" sm="9">
                              <Select
                                value={producto}
                                options={productos}
                                isLoading={loadingProductos}
                                placeholder={"Seleccione..."}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label text-start">
                              Plan:
                            </Label>

                            <Col xl="6" sm="9">
                              <Select
                                value={plan}
                                options={planes}
                                placeholder={"Seleccione..."}
                              />
                            </Col>
                          </FormGroup>
                          {plazos.length > 0 && (
                            <FormGroup className="row">
                              <Label className="col-sm-3 col-form-label text-start">
                                Plazo:
                              </Label>

                              <Col xl="6" sm="9">
                                <Select
                                  value={plazo}
                                  options={plazos}
                                  placeholder={"Seleccione..."}
                                />
                              </Col>
                            </FormGroup>
                          )}
                          <FormGroup className="row">
                            <Label className="col-form-label text-start">
                              Comentarios:
                            </Label>
                            <Col xl="12" sm="9">
                              <Input
                                type="textarea"
                                value={comentarios}
                                id="comentarios"
                                className="form-control input-air-primary"
                                rows="3"
                              />
                            </Col>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="4">
                    <div
                      className="project-box"
                      style={{ paddingBottom: "2px" }}
                    >
                      <span className="badge badge-primary">Opciones</span>
                      <div>
                        <div>
                          <FormGroup>
                            <div className="checkbox">
                              <Input
                                id="Control"
                                type="checkbox"
                                checked={control}
                                disabled={!opciones.includes("CONTROL")}
                              />
                              <Label for="Control">Control</Label>
                            </div>
                            <div className="checkbox">
                              <Input
                                id="Seguro"
                                type="checkbox"
                                checked={seguro}
                                disabled={!opciones.includes("SEGURO")}
                              />
                              <Label for="Seguro">Seguro</Label>
                            </div>
                            <div className="checkbox">
                              <Input
                                id="Noches"
                                type="checkbox"
                                checked={noches}
                                disabled={!opciones.includes("NOCHES")}
                              />
                              <Label for="Noches">Fines de semana</Label>
                            </div>
                            <div className="checkbox">
                              <Input
                                id="NYF"
                                type="checkbox"
                                checked={nyf}
                                disabled={!opciones.includes("NYF")}
                              />
                              <Label for="NYF">NYF</Label>
                            </div>
                            <div className="checkbox">
                              <Input
                                id="HBO"
                                type="checkbox"
                                checked={hbo}
                                disabled={!opciones.includes("HBO")}
                              />
                              <Label for="HBO">HBO</Label>
                            </div>
                            <div className="checkbox">
                              <Input
                                id="Domicilio"
                                type="checkbox"
                                checked={domicilio}
                                disabled={!opciones.includes("ENVIO DOMICILIO")}
                              />
                              <Label for="Domicilio">Domicilio</Label>
                            </div>
                            <div className="checkbox">
                              <Input
                                id="Pro-Bussines"
                                type="checkbox"
                                checked={proBussines}
                                disabled={
                                  !opciones.includes("PRO BUSSINES APPS")
                                }
                              />
                              <Label for="Pro-Bussines">Pro-Bussines</Label>
                            </div>
                          </FormGroup>
                          {metodosVenta.length > 0 && (
                            <FormGroup className="row">
                              <Label className="col-form-label text-start">
                                Método de venta:
                              </Label>
                              <Col xl="12" sm="12">
                                <Select
                                  value={metodoVenta}
                                  options={metodosVenta}
                                  placeholder={"Seleccione..."}
                                />
                              </Col>
                            </FormGroup>
                          )}
                          {multirenta != -1 && (
                            <FormGroup className="row">
                              <Label className="col-form-label text-start">
                                Dto (%) Multi-Renta:
                              </Label>
                              <Col xl="12" sm="12">
                                <Input
                                  id="descuento"
                                  type="number"
                                  value={multirenta}
                                  placeholder="Descuento (%)"
                                />
                              </Col>
                            </FormGroup>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Fragment>
              ) : (
                <Col sm="12" md="12">
                  <div className="project-box" style={{ paddingBottom: "5px" }}>
                    <span className="badge badge-primary">Identificación</span>
                    <div>
                      <div>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label text-start">
                            Fecha:
                          </Label>
                          <Col xl="6" sm="9">
                            <DatePicker
                              className="form-control digits"
                              showPopperArrow={true}
                              selected={fecha}
                              dateFormat="dd/MM/yyyy"
                              locale="es"
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label text-start">
                            ICC/SIM:
                          </Label>
                          <Col xl="6" sm="9">
                            <Input
                              value={co_id}
                              id="imei"
                              placeholder="IMEI..."
                              type="text"
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label text-start">
                            Producto:
                          </Label>

                          <Col xl="6" sm="9">
                            <Select
                              value={producto}
                              options={productos}
                              isLoading={loadingProductos}
                              placeholder={"Seleccione..."}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label text-start">
                            Recarga:
                          </Label>

                          <Col xl="6" sm="9">
                            <Select
                              value={plan}
                              options={planes}
                              placeholder={"Seleccione..."}
                            />
                          </Col>
                        </FormGroup>
                        {showMonto && (
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label text-start">
                              Otro monto:
                            </Label>
                            <Col xl="6" sm="9">
                              <Input
                                value={monto}
                                id="otro_monto"
                                placeholder="$0.00"
                                type="number"
                              />
                            </Col>
                          </FormGroup>
                        )}
                        <FormGroup className="row">
                          <Label className="col-form-label text-start">
                            Comentarios:
                          </Label>
                          <Col xl="12" sm="9">
                            <Input
                              value={comentarios}
                              type="textarea"
                              id="comentarios"
                              className="form-control input-air-primary"
                              rows="3"
                            />
                          </Col>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Btn attrBtn={{ color: "primary", onClick: toggle }}>Aceptar</Btn>
          </ModalFooter>
        </Form>
      </Modal>
    </CommonModal>
  );
};

export default SalesModalFormView;
