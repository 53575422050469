import React, { Fragment, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { Breadcrumbs, Btn, H6, P } from "../../AbstractElements";
import Request from "../../api/httpClient";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardHeader,
} from "reactstrap";
import HeaderCard from "../Common/Component/HeaderCard";
import { format } from "date-fns";
import DataTableComponent from "../Tables/DataTable/DataTableComponent";
import moment from "moment";
import { saveAs } from "file-saver";
import GerentesTab from "./GerentesTab";
const request = new Request();
const ExcelJS = require("exceljs");

const GerentesPage = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [fecha, setFecha] = useState(new Date());
  const [comisiones, setComisiones] = useState(0);

  //obtener el total de las comisiones
  const getComisiones = async () => {
    setComisiones(0);
    // setLoadingComisiones(true);

    //si el agente no ha seleccionado el empleado que quiere utilizar, en el menu sucursales, entonces utilizo
    //el id de usuario del agente, sino utilizo el id de usuario del empleado seleccionado
    const id_agente =
      JSON.parse(localStorage.empleado_selec) !== null
        ? JSON.parse(localStorage.empleado_selec).value
        : localStorage.getItem("user_id");

    let sucursalInfo = 0;

    if (JSON.parse(localStorage.sucursal_selec) !== null) {
      if (localStorage.getItem("SucursalesInfo") == 0) {
        sucursalInfo = +JSON.parse(localStorage.sucursal_selec).value;
      }
    }

    let data = {
      id_usuario: +id_agente,
      periodo: fecha,
      id_sucursal: sucursalInfo,
    };
    //console.log(data);
    const response = await request.post("/gerentes/get/comisiones/", data);
    if (response && !response.error) {
      if (
        response.comisiones &&
        response.comisiones.length > 0 &&
        !response.empty
      ) {
        setComisiones(response.comisiones[0].Comision);
        //console.log("comisiones" + response.comisiones);
      } else {
        setComisiones(0);
        //setLoadingComisiones(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    //setLoadingComisiones(false);
  };

  useEffect(() => {
    getComisiones();
  }, [fecha]);

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Gerentes" parent="General" title="Gerentes" />
      <Container fluid={true} className="chart-widget">
        <Row>
          <Col sm="12">
            <Card style={{ minHeight: "65vh" }}>
              <CardHeader>
                <Row>
                  <Col sm="6">
                    <H6>Calculadora</H6>
                  </Col>

                  <Col sm="3">
                    <Row>
                      <Label className="col-sm-6 col-form-label text-end">
                        Comisión:
                      </Label>

                      <Col sm="6">
                        {comisiones == -1 ? (
                          <Input
                            id="comisiones"
                            type="text"
                            value="Sin datos"
                            readOnly
                          />
                        ) : (
                          <Input
                            id="comisiones"
                            type="number"
                            value={comisiones}
                            readOnly
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="3">
                    <Row>
                      <Label className="col-sm-6 col-form-label text-end">
                        Período:
                      </Label>

                      <Col sm="6">
                        {" "}
                        <ReactDatePicker
                          className="form-control digits"
                          selected={fecha}
                          maxDate={new Date()}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          locale="en"
                          onChange={(date) => {
                            setFecha(date);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ paddingTop: "0px" }}>
                <Row className="m-0">
                  <Col sm="12">
                    <Nav tabs className="border-tab nav-primary mb-0 ">
                      <NavItem id="myTab" role="tablist">
                        <NavLink
                          href="#"
                          className={activeTab === "1" ? "active" : ""}
                          onClick={() => setActiveTab("1")}
                        >
                          Activaciones
                        </NavLink>
                        <div className="material-border"></div>
                      </NavItem>
                      <NavItem id="myTab" role="tablist">
                        <NavLink
                          href="#"
                          className={activeTab === "2" ? "active" : ""}
                          onClick={() => setActiveTab("2")}
                        >
                          Activaciones simples
                        </NavLink>
                        <div className="material-border"></div>
                      </NavItem>
                      <NavItem id="myTab" role="tablist">
                        <NavLink
                          href="#"
                          className={activeTab === "3" ? "active" : ""}
                          onClick={() => setActiveTab("3")}
                        >
                          Renovaciones
                        </NavLink>
                        <div className="material-border"></div>
                      </NavItem>
                      <NavItem id="myTab" role="tablist">
                        <NavLink
                          href="#"
                          className={activeTab === "4" ? "active" : ""}
                          onClick={() => setActiveTab("4")}
                        >
                          Renovaciones simples
                        </NavLink>
                        <div className="material-border"></div>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <GerentesTab
                          title={"Activaciones"}
                          id_tipoVenta={1}
                          id_familia={1}
                          activeTab={activeTab}
                          tabId="1"
                          fecha={fecha}
                        />
                      </TabPane>
                      <TabPane tabId="2">
                        <GerentesTab
                          title={"Activaciones simples"}
                          id_tipoVenta={1}
                          id_familia={4}
                          activeTab={activeTab}
                          tabId="2"
                          fecha={fecha}
                        />
                      </TabPane>
                      <TabPane tabId="3">
                        <GerentesTab
                          title={"Renovaciones"}
                          id_tipoVenta={2}
                          id_familia={1}
                          activeTab={activeTab}
                          tabId="3"
                          fecha={fecha}
                        />
                      </TabPane>
                      <TabPane tabId="4">
                        <GerentesTab
                          title={"Renovaciones simples"}
                          id_tipoVenta={2}
                          id_familia={4}
                          activeTab={activeTab}
                          tabId="4"
                          fecha={fecha}
                        />
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GerentesPage;
