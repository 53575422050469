import axios from "axios";
import { format } from "date-fns";
import React, { Fragment, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
} from "reactstrap";
import Swal from "sweetalert2";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import Request from "../../api/httpClient";
import { CreateNewSale, PaddingTop } from "../../Constant";
import HeaderCard from "../Common/Component/HeaderCard";
import DataTableComponent from "../Tables/DataTable/DataTableComponent";
import ModalButton from "../UiKits/Modals/VaryingContent/common/ModalButton";
import SalesModalButton from "./SalesModalButton";
import SalesModalFormEdit from "./SalesModalFormEdit";
import SalesModalFormView from "./SalesModalFormView";
const request = new Request();

const SalesPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [ventas, setVentas] = useState([]);
  const [venta, setVenta] = useState([]);
  const [loadingVentas, setLoadingVentas] = useState(false);
  const [productos, setProductos] = useState([]);
  const [loadingProductos, setLoadingProductos] = useState(false);

  const [fecha_fin, setFechaFin] = useState(new Date());
  const [userTypeID, setUserTypeID] = useState(localStorage.getItem("TypeID"));

  const [fecha_inicio_min, setFechaInicioMin] = useState(() => {
    switch (userTypeID) {
      case "0": //Agente
        return new Date(fecha_fin.getTime() - 5 * 24 * 60 * 60 * 1000);
      case "1": //Admin
        return new Date(fecha_fin.getTime() - 60 * 24 * 60 * 60 * 1000);
      default: //Gerente
        return new Date(fecha_fin.getTime() - 60 * 24 * 60 * 60 * 1000);
    }
  });
  const [fecha_inicio, setFechaInicio] = useState(() => {
    switch (userTypeID) {
      case "0": //Agente
        return new Date(fecha_fin.getTime() - 1 * 24 * 60 * 60 * 1000);
      case "1": //Admin
        return new Date(fecha_fin.getTime() - 7 * 24 * 60 * 60 * 1000);
      default: //Gerente
        return new Date(fecha_fin.getTime() - 3 * 24 * 60 * 60 * 1000);
    }
  });
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [modalView, setModalView] = useState(false);
  const toggleView = () => setModalView(!modalView);
  const [title, setTitle] = useState("Mi registro de ventas");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClick = () => {
    const filteredData = ventas.filter((item) =>
      item.CodID.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setVentas(filteredData);
  };

  const filteredData = ventas.filter((item) =>
    item.CodID.toLowerCase().includes(searchTerm.toLowerCase())
  );

  //obtener todas las ventas
  const getVentas = async () => {
    setVentas([]);
    setLoadingVentas(true);

    //si el agente no ha seleccionado el empleado que quiere utilizar, en el menu sucursales, entonces utilizo
    //el id de usuario del agente, sino utilizo el id de usuario del empleado seleccionado
    const id_agente =
      JSON.parse(localStorage.empleado_selec) !== null
        ? JSON.parse(localStorage.empleado_selec).value
        : localStorage.getItem("user_id");

    let sucursalInfo = 0;

    if (JSON.parse(localStorage.sucursal_selec) !== null) {
      if (localStorage.getItem("SucursalesInfo") == 0) {
        sucursalInfo = +JSON.parse(localStorage.sucursal_selec).value;
      }
    }

    let data = {
      id_sucursal: sucursalInfo,
      id_agente: id_agente || 0,
      fecha_inicio: fecha_inicio,
      fecha_fin: fecha_fin,
    };

    //console.log(data);
    const response = await request.post("/ventas/get/all/", data);
    if (response && !response.error) {
      if (response.ventas && !response.empty) {
        setVentas(response.ventas);
        //console.log(response.ventas);
      } else {
        setVentas([]);
        setLoadingVentas(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingVentas(false);
  };

  useEffect(() => {
    getVentas();
  }, []);

  const handleEditarVenta = (row) => {
    //console.log(ventas);
    setVenta(row);
    toggleEdit();
  };

  const handleVerVenta = (row) => {
    //console.log(ventas);
    setVenta(row);
    toggleView();
  };

  const handleEliminarVenta = (row) => {
    console.log(row);
    Swal.fire({
      title: "Está seguro de eliminar esta venta?",
      text: "Se eliminará la venta con CO ID: " + row.CodID,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {
          type: "DELETE",
          id_venta: row.Id_venta,
          usuario_delete: localStorage.getItem("user_id"),
          fecha_delete: new Date(),
        };

        const response = await request.post("/ventas/venta/delete", data);
        if (response.deleted) {
          Swal.fire("Eliminado!", "Esta venta ha sido eliminada", "success");
          reload();
        } else {
          toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        }
      }
    });
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.CodID === "Venta Domicilio",
      style: {
        //backgroundColor: "rgba(63, 195, 128, 0.9)",
        color: "red",
      },
    },
  ];

  const tableColumns = [
    {
      name: "CO_ID (IMEI)",
      minWidth: "160px",
      selector: (row) => `${row.CodID}`,
      sortable: true,
      center: false,
    },
    {
      name: <div style={{ width: "50%", textAlign: "center" }}>Tipo</div>,
      selector: (row) => `${row.Tipo}`,
      sortable: true,
      center: false,
    },
    {
      name: <div style={{ width: "50%", textAlign: "center" }}>Producto</div>,
      minWidth: "400px",
      selector: (row) => `${row.Marca}`,
      sortable: true,
      center: false,
    },
    {
      name: "Fecha",
      selector: (row) => format(new Date(row.Fecha), "dd-MM-yyyy"),
      sortable: true,
      center: false,
    },
    {
      name: <div style={{ width: "100%", textAlign: "center" }}>Acciones</div>,
      minWidth: "200px",
      button: true,
      ignoreRowClick: true,
      allowOverflow: true,
      center: false,
      cell: (row) => (
        <Row>
          <Col
            xl="6"
            md="12"
            style={{
              display:
                row.CodID === "Venta Domicilio" ||
                new Date(row.Fecha) >= fecha_inicio_min
                  ? "none"
                  : "block",
            }}
          >
            <Btn
              attrBtn={{
                style: {
                  width: 80,
                  fontSize: 14,
                  padding: 4,
                  marginRight: "10px",
                },
                color: "info",
                className: "btn btn-xs",
                type: "button",
                onClick: () => {
                  handleVerVenta(row);
                },
              }}
            >
              <i className="fa fa-eye">
                {" "}
                <span style={{ fontFamily: "Rubik, sans-serif" }}>Ver</span>
              </i>
            </Btn>
          </Col>
          <Col
            xl="6"
            md="12"
            style={{
              display:
                row.CodID !== "Venta Domicilio" &&
                new Date(row.Fecha) < fecha_inicio_min
                  ? "none"
                  : "block",
            }}
          >
            <Btn
              attrBtn={{
                style: {
                  width: 80,
                  fontSize: 14,
                  padding: 4,
                  marginRight: "10px",
                },
                color: "info",
                className: "btn btn-xs",
                type: "button",
                onClick: () => {
                  handleEditarVenta(row);
                },
              }}
            >
              <i className="fa fa-edit">
                {" "}
                <span style={{ fontFamily: "Rubik, sans-serif" }}>Editar</span>
              </i>
            </Btn>
          </Col>
          <Col
            xl="6"
            md="12"
            style={{
              display:
                row.CodID !== "Venta Domicilio" &&
                new Date(row.Fecha) < fecha_inicio_min
                  ? "none"
                  : "block",
            }}
          >
            {(row.CodID === "Venta Domicilio" ||
              (row.CodID !== "Venta Domicilio" && userTypeID >= 1)) && (
              <Btn
                attrBtn={{
                  style: {
                    width: 80,
                    fontSize: 14,
                    padding: 4,
                  },
                  color: "secondary",
                  className: "btn btn-xs",
                  type: "button",
                  onClick: () => {
                    handleEliminarVenta(row);
                  },
                }}
              >
                <i className="fa fa-remove">
                  {" "}
                  <span style={{ fontFamily: "Rubik, sans-serif" }}>
                    Eliminar
                  </span>
                </i>
              </Btn>
            )}
          </Col>
        </Row>
      ),
    },
  ];

  const reload = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 2000,
    });
    getVentas();
  };

  return (
    <Fragment>
      <Breadcrumbs parent="General" title="Ventas" mainTitle="Ventas" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card style={{ minHeight: "65vh" }}>
              <HeaderCard title="Registro de ventas" />
              <Col sm="12">
                <Row>
                  <Col sm="6">
                    <div className="product-search feature-products">
                      <Form
                        style={{
                          paddingTop: "15px",
                          paddingLeft: "25px",
                          paddingRight: "20px",
                          width: "80%",
                        }}
                      >
                        <FormGroup className="m-0 form-group search-product">
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="Buscar..."
                            value={searchTerm}
                            onChange={handleInputChange}
                          />
                          <i className="fa fa-search"></i>
                        </FormGroup>
                      </Form>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div
                      className="text-end btn-showcase"
                      style={{ paddingRight: "50px", paddingTop: "15px" }}
                    >
                      <SalesModalButton
                        btnText="Activación"
                        title="Activación"
                        type="1"
                        reload={reload}
                        userTypeID={userTypeID}
                      />
                      <SalesModalButton
                        btnText="Cambio Oferta"
                        title="Cambio de oferta"
                        type="2"
                        reload={reload}
                        userTypeID={userTypeID}
                      />
                      <SalesModalButton
                        btnText="Prepago"
                        title="Prepago"
                        type="3"
                        reload={reload}
                        userTypeID={userTypeID}
                      />
                      {modalView && (
                        <SalesModalFormView
                          modal={true}
                          title={"Ver " + venta.Tipo}
                          toggle={toggleView}
                          size="lg"
                          type={venta.Id_TipoVenta}
                          reload={reload}
                          venta={venta}
                        />
                      )}
                      {modalEdit && (
                        <SalesModalFormEdit
                          modal={true}
                          title={"Editar " + venta.Tipo}
                          toggle={toggleEdit}
                          size="lg"
                          type={venta.Id_TipoVenta}
                          reload={reload}
                          venta={venta}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
              <CardBody style={{ paddingTop: "0px" }}>
                <Row
                  className="justify-content-end"
                  style={{ paddingBottom: "20px" }}
                >
                  <Col sm="3">
                    <Row style={{ paddingTop: "20px" }}>
                      <Label className="col-sm-6 col-form-label text-end">
                        Fecha de inicio:
                      </Label>

                      <Col sm="6">
                        {" "}
                        <ReactDatePicker
                          className="form-control digits"
                          selected={fecha_inicio}
                          // minDate={fecha_inicio_min}
                          maxDate={fecha_fin}
                          dateFormat="dd/MM/yyyy"
                          locale="es"
                          onChange={(date) => {
                            setFechaInicio(date);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="3">
                    <Row style={{ paddingTop: "20px" }}>
                      <Label className="col-sm-6 col-form-label text-end">
                        Fecha de fin:
                      </Label>

                      <Col sm="6">
                        {" "}
                        <ReactDatePicker
                          className="form-control digits"
                          selected={fecha_fin}
                          maxDate={new Date()}
                          //minDate={fecha_inicio}
                          dateFormat="dd/MM/yyyy"
                          locale="es"
                          onChange={(date) => {
                            setFechaFin(date);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col sm="2">
                    <Row
                      className="justify-content-end"
                      style={{ paddingTop: "20px", paddingRight: "35px" }}
                    >
                      <Btn
                        attrBtn={{
                          style: {
                            width: 86,
                            fontSize: 15,
                            padding: 6,
                            marginRight: "10px",
                          },
                          color: "info",
                          className: "btn btn-xs",
                          type: "button",
                          onClick: () => {
                            getVentas();
                          },
                        }}
                      >
                        <i className="fa fa-check">
                          {" "}
                          <span style={{ fontFamily: "Rubik, sans-serif" }}>
                            Aplicar
                          </span>
                        </i>
                      </Btn>
                    </Row>
                  </Col>
                </Row>
                <DataTableComponent
                  tableColumns={tableColumns}
                  tableRows={filteredData}
                  loadingVentas={loadingVentas}
                  //conditionalRowStyles={conditionalRowStyles}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SalesPage;
