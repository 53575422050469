import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FileText, LogIn, Sidebar, User } from "react-feather";
import man from "../../../assets/images/user/user.png";

import { LI, UL, Image, P } from "../../../AbstractElements";
import CustomizerContext from "../../../_helper/Customizer";
import { Account, Admin, Inbox, LogOut, Taskboard } from "../../../Constant";
import { Fragment } from "react";
import TabCustomizer from "../../ThemeCustomizer/TabCustomizer";
import ChangeSucursalMenu from "../../../Components/Pages/Auth/ChangeSucursalMenu";

const UserHeader = () => {
  const history = useNavigate();
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const { layoutURL } = useContext(CustomizerContext);
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));

  const [openCus, setOpenCus] = useState(false);
  const [userDropdown, setUserDropDown] = useState(false);

  useEffect(() => {
    setProfile(localStorage.getItem("profileURL") || man);
    setName(localStorage.getItem("Name") ? localStorage.getItem("Name") : name);
    setType(localStorage.getItem("Type") ? localStorage.getItem("Type") : type);
  }, []);

  const Logout = () => {
    localStorage.removeItem("profileURL");
    localStorage.removeItem("token");
    localStorage.removeItem("auth0_profile");
    localStorage.removeItem("Name");
    localStorage.removeItem("Type");
    localStorage.setItem("authenticated", false);
    localStorage.setItem("login", false);
    history(`/login`);
  };

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  const callbackNav = (open) => {
    setOpenCus(open);
  };

  return (
    <Fragment>
      <li className="profile-nav onhover-dropdown pe-0 py-0">
        <div
          className="media profile-media"
          onClick={() => setUserDropDown(!userDropdown)}
        >
          <Image
            attrImage={{
              className: "b-r-10 m-0",
              src: `${authenticated ? auth0_profile.picture : profile}`,
              alt: "",
            }}
          />
          <div className="media-body">
            <span>
              {authenticated
                ? auth0_profile.name
                : name.split(" ").slice(0, 2).join(" ")}
            </span>
            <P attrPara={{ className: "mb-0 font-roboto" }}>
              {type} <i className="middle fa fa-angle-down"></i>
            </P>
          </div>
        </div>

        {userDropdown && (
          <UL
            attrUL={{
              className: `simple-list profile-dropdown onhover-show-div ${
                userDropdown ? "active" : ""
              }`,
            }}
          >
            <LI
              attrLI={{
                onClick: () => {
                  setOpenCus(true);
                },
              }}
            >
              <Sidebar />
              <span>Sucursales</span>
            </LI>

            <LI attrLI={{ onClick: Logout }}>
              <LogIn />
              <span>Cerrar sesión</span>
            </LI>
          </UL>
        )}
      </li>
      <div className={`customizer-contain ${openCus ? "open" : ""}`}>
        <ChangeSucursalMenu callbackNavTab={callbackNav} />
      </div>
    </Fragment>
  );
};

export default UserHeader;
