import React, { Fragment, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
  Media,
  Row,
} from "reactstrap";
import CommonModal from "../UiKits/Modals/common/modal";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Btn, H6 } from "../../AbstractElements";
import Request from "../../api/httpClient";
import Select from "react-select";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";

registerLocale("es", es);

const SalesModalForm = ({
  modal,
  title,
  toggle,
  size,
  type,
  reload,
  userTypeID,
}) => {
  const [planes, setPlanes] = useState([]);
  const [plazos, setPlazos] = useState([]);

  const [opciones, setOpciones] = useState([]);
  const [metodosVenta, setMetodosVenta] = useState([]);

  const [fecha, setFecha] = useState(new Date());
  const [co_id, setCo_Id] = useState("");
  const [co_id_confirm, setCo_Id_Confirm] = useState("");
  const [comentarios, setComentarios] = useState("");
  const [producto, setProducto] = useState("");
  const [plan, setPlan] = useState("");
  const [plazo, setPlazo] = useState("");
  const [metodoVenta, setMetodoVenta] = useState("");
  const [recarga, setRecarga] = useState("");

  const [control, setControl] = useState(false);
  const [seguro, setSeguro] = useState(false);
  const [montoSeguro, setMontoSeguro] = useState(0);
  const [seguros, setSeguros] = useState([]);
  const [loadingSeguros, setLoadingSeguros] = useState(false);
  const [noches, setNoches] = useState(false);
  const [nyf, setNYF] = useState(false);
  const [hbo, setHBO] = useState(false);
  const [domicilio, setDomicilio] = useState(false);
  const [proBussines, setProBussines] = useState(false);
  const [multirenta, setMultirenta] = useState(0);
  const [showMultiRenta, setShowMultirenta] = useState(false);
  const [monto, setMonto] = useState(0);
  const [showMonto, setShowMonto] = useState(false);
  const [color, setColor] = useState("blue");

  const [productos, setProductos] = useState([]);
  const [loadingProductos, setLoadingProductos] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [showVentaDomicilio, setShowVentaDomicilio] = useState(false);
  const request = new Request();

  //obtener todas los productos de un tipo
  const getProductos = async () => {
    setProductos([]);
    setLoadingProductos(true);

    let data = {
      tipo: type,
    };

    const response = await request.post("/ventas/productos/get/type/", data);
    if (response && !response.error) {
      if (response.productos && !response.empty) {
        // console.log(response.productos);
        setProductos(FormatearProductos(response.productos));
      } else {
        setProductos([]);
        setLoadingProductos(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingProductos(false);
  };

  //obtener todos los seguros
  const getSeguros = async () => {
    setSeguros([]);
    setLoadingSeguros(true);

    const response = await request.get("/ventas/get/seguros/all");
    if (response && !response.error) {
      if (response.seguros && !response.empty) {
        //console.log(response.seguros);
        const seguros = response.seguros.map((seguro) => ({
          value: seguro.MONTO,
          label: seguro.MONTO,
        }));
        setSeguros(seguros);
      } else {
        setSeguros([]);
        setLoadingSeguros(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingSeguros(false);
  };

  useEffect(() => {
    if (modal) {
      LimpiarDatos();
      getProductos();
      getSeguros();
    }
  }, [modal]);

  //cambiar el formato del arreglo de productos que recibo para poder utilizarlo en los componentes de la UI
  const FormatearProductos = (productos) => {
    const productos_formateados = productos.flatMap((obj) =>
      Object.values(obj).map((val) => ({
        value: val.Id_Clasificacion,
        label: val.Clasificacion,
        id_planes: val.Id_Plan,
        planes: val.Plan,
        seleccion: val.Seleccion,
        valor: val.valor,
      }))
    );
    //console.log(productos);
    return productos_formateados;
  };

  //limpiar checkoxes de opciones
  const LimpiarDatos = () => {
    //inputs
    setCo_Id("");
    setCo_Id_Confirm("");
    setMultirenta(0);
    setShowMultirenta(false);
    setMonto(0);
    setShowMonto(false);
    setComentarios("");

    //comboboxes
    setPlanes([]);
    setPlazos([]);

    //valores seleccionados en los comboboxes
    setPlan("");
    setPlazo("");
    setRecarga("");
    setMetodosVenta([]);

    //opciones - checboxes
    setControl(false);
    setSeguro(false);
    setMontoSeguro(0);
    setNoches(false);
    setNYF(false);
    setHBO(false);
    setDomicilio(false);
    setProBussines(false);

    setShowVentaDomicilio(false);
  };

  //obtener los datos de la ventana segun el producto seleccionado
  const ActualizarDatos = (producto) => {
    setProducto(producto);
    //console.log(producto);

    //planes
    const planes_array = Array.from(
      new Set(producto.planes.toString().split(", "))
    );
    const planes_id_array = Array.from(
      new Set(producto.id_planes.toString().split(", "))
    );
    const planes = [];

    for (let i = 0; i < planes_array.length; i++) {
      planes.push({
        value: planes_id_array[i],
        label: planes_array[i],
      });
    }
    setPlanes(planes);

    let seleccion_array = [];
    let valores_array = [];
    //opciones
    if (producto.seleccion !== null) {
      seleccion_array = Array.from(
        new Set(producto.seleccion.toString().split(", "))
      );
    }
    if (producto.valor !== null) {
      /* valores_array = Array.from(
        new Set(producto.valor.toString().split(", "))
      );*/
      valores_array = [
        ...new Set(
          producto.valor
            .toString()
            .split(", ")
            .map((valor) => valor.replace(/\s/g, ""))
        ),
      ];
    }

    if (seleccion_array.includes("PLAZO")) {
      const plazos = valores_array
        .filter((valor) => valor.includes("Meses"))
        .map((val) => ({
          value: val,
          label: val,
        }));
      setPlazos(plazos);
      setOpciones([]);
    }

    if (seleccion_array.includes("METODO DE VENTA")) {
      const metodos_ventas = valores_array
        .filter((valor) => !valor.includes("Meses") && !valor.includes("SI"))
        .map((val) => ({
          value: val,
          label: val,
        }));
      setMetodosVenta(metodos_ventas);
    }
    if (seleccion_array.includes("DTO MULTI RENTA")) {
      setShowMultirenta(true);
    } else {
      setShowMultirenta(false);
    }

    if (!seleccion_array.includes("PLAZO")) {
      setPlazos([]);
    }
    if (!seleccion_array.includes("METODO DE VENTA")) {
      setMetodosVenta([]);
    }
    if (seleccion_array.includes("ENVIO DOMICILIO")) {
      setShowVentaDomicilio(true);
    } else {
      setShowVentaDomicilio(false);
    }

    setOpciones(seleccion_array);

    setPlan("");
    setPlazo("");
    setRecarga("");
    setMetodoVenta("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitDisabled(true);
    const id_agente =
      JSON.parse(localStorage.empleado_selec) !== null
        ? JSON.parse(localStorage.empleado_selec).value
        : localStorage.getItem("user_id");
    if (co_id === co_id_confirm) {
      let data = {
        type: "INSERT",
        id_venta: 0,
        tipo_venta: type,
        id_agente: +id_agente,
        id_sucursal: +JSON.parse(localStorage.sucursal_selec).value,
        fecha: new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate()),
        codID: co_id,
        id_fabricante: +producto.value,
        id_marca: +plan.value,
        control: control,
        seguro: seguro,
        monto_seguro: montoSeguro != null ? montoSeguro.value : null,
        noche: noches,
        nyf: nyf,
        hbo: hbo,
        envio_domicilio: domicilio,
        plazo: plazo.value,
        proBusiness: proBussines,
        metodoVenta: metodoVenta.value,
        dtoMultiRenta: multirenta,
        monto: monto,
        comentarios: comentarios,
        usuario_update: null,
        fecha_update: null,
        usuario_delete: null,
        fecha_delete: null,
        id_status: 1,
        color: color,
      };

      const response = await request.post("/ventas/venta/create", data);

      if (response.created) {
        toggle();
        setIsSubmitDisabled(false);
        reload(response.message);
      } else {
        setIsSubmitDisabled(false);
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
    } else {
      setIsSubmitDisabled(false);
    }
  };

  return (
    <CommonModal isOpen={modal} title={title} toggler={toggle} size={size}>
      <Modal isOpen={modal} toggle={toggle} size={size} centered>
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggle}>{title}</ModalHeader>

          <ModalBody style={{ paddingTop: "2px", paddingBottom: "2px" }}>
            {type == 1 && showVentaDomicilio && (
              <Media>
                <Label className="col-form-label m-r-10">
                  Venta con entrega a domicilio
                </Label>
                <Media
                  body
                  className="text-start icon-state switch-outline p-l-50"
                >
                  <Label className="switch">
                    <Input
                      type="checkbox"
                      checked={domicilio}
                      onChange={(event) => {
                        setDomicilio(event.target.checked);
                        setCo_Id("");
                        setCo_Id_Confirm("");
                      }}
                    />
                    <span className="switch-state bg-primary"></span>
                  </Label>
                </Media>
              </Media>
            )}

            <Row>
              {type != 3 ? (
                <Fragment>
                  <Col sm="12" md="8">
                    <div
                      className="project-box"
                      style={{ paddingBottom: "5px" }}
                    >
                      {/* <H6>Identificación</H6> */}
                      <span className="badge badge-primary">
                        Identificación
                      </span>
                      <div>
                        <div>
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label text-start">
                              Fecha:
                            </Label>
                            <Col xl="6" sm="9">
                              <DatePicker
                                className="form-control digits"
                                showPopperArrow={true}
                                selected={fecha}
                                maxDate={new Date()}
                                minDate={
                                  userTypeID === "0"
                                    ? new Date(
                                        fecha.getTime() -
                                          1 * 24 * 60 * 60 * 1000
                                      )
                                    : undefined
                                }
                                dateFormat="dd/MM/yyyy"
                                locale="es"
                                onChange={(date) => {
                                  setFecha(date);
                                }}
                                required
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label text-start">
                              CO_ID:
                            </Label>
                            <Col xl="6" sm="9">
                              <Input
                                id="co_id"
                                type="password"
                                value={co_id}
                                placeholder="CO_ID... "
                                onChange={(e) => {
                                  setCo_Id(e.target.value);
                                }}
                                onInvalid={(e) => {
                                  e.target.setCustomValidity(
                                    "Valor incorrecto. Revise los datos."
                                  );
                                }}
                                onInput={(e) => {
                                  e.target.setCustomValidity("");
                                }}
                                title=""
                                disabled={domicilio}
                                autoComplete={"new-password"}
                                minLength={type == 1 ? "9" : "5"}
                                maxLength={type == 1 ? "9" : "20"}
                                required
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label text-start">
                              CO_ID:
                            </Label>
                            <Col xl="6" sm="9">
                              <Input
                                id="co_id_confirm"
                                value={co_id_confirm}
                                placeholder="Confirmar CO_ID"
                                onChange={(e) => {
                                  setCo_Id_Confirm(e.target.value);
                                }}
                                onInvalid={(e) => {
                                  e.target.setCustomValidity(
                                    "Valor incorrecto. Revise los datos."
                                  );
                                }}
                                onInput={(e) => {
                                  e.target.setCustomValidity("");
                                }}
                                title=""
                                disabled={domicilio}
                                autoComplete={"new-password"}
                                minLength={type == 1 ? "9" : "5"}
                                maxLength={type == 1 ? "9" : "20"}
                                required
                              />
                              {co_id &&
                                co_id_confirm &&
                                (co_id === co_id_confirm ? (
                                  <span style={{ color: "green" }}>
                                    Los co_id coinciden
                                  </span>
                                ) : (
                                  <span style={{ color: "red" }}>
                                    Los co_id no coinciden
                                  </span>
                                ))}
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label text-start">
                              Producto:
                            </Label>

                            <Col xl="6" sm="9">
                              <Select
                                options={productos}
                                isLoading={loadingProductos}
                                onChange={(producto) =>
                                  ActualizarDatos(producto)
                                }
                                placeholder={"Seleccione..."}
                                required
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label text-start">
                              Plan:
                            </Label>

                            <Col xl="6" sm="9">
                              <Select
                                value={plan}
                                options={planes}
                                placeholder={"Seleccione..."}
                                onChange={(plan) => setPlan(plan)}
                                required
                              />
                            </Col>
                          </FormGroup>
                          {plazos.length > 0 && (
                            <FormGroup className="row">
                              <Label className="col-sm-3 col-form-label text-start">
                                Plazo:
                              </Label>

                              <Col xl="6" sm="9">
                                <Select
                                  value={plazo}
                                  options={plazos}
                                  placeholder={"Seleccione..."}
                                  onChange={(plazo) => setPlazo(plazo)}
                                  required
                                />
                              </Col>
                            </FormGroup>
                          )}
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label text-start">
                              Portal:
                            </Label>

                            <Col xl="6" sm="9">
                              <div className="m-checkbox-inline custom-radio-ml">
                                <div className="radio radio-danger">
                                  <Input
                                    id="radioinline1"
                                    type="radio"
                                    name="radio1"
                                    value="red"
                                    onChange={(event) => {
                                      setColor(event.target.value);
                                    }}
                                  />
                                  <Label
                                    className="mb-0"
                                    for="radioinline1"
                                  ></Label>
                                </div>
                                <div className="radio radio-primary">
                                  <Input
                                    id="radioinline2"
                                    type="radio"
                                    name="radio1"
                                    value="blue"
                                    onChange={(event) => {
                                      setColor(event.target.value);
                                    }}
                                    defaultChecked
                                  />
                                  <Label
                                    className="mb-0"
                                    for="radioinline2"
                                  ></Label>
                                </div>
                                <div className="radio radio-warning">
                                  <Input
                                    id="radioinline3"
                                    type="radio"
                                    name="radio1"
                                    value="orange"
                                    onChange={(event) => {
                                      setColor(event.target.value);
                                    }}
                                  />
                                  <Label
                                    className="mb-0"
                                    for="radioinline3"
                                  ></Label>
                                </div>
                              </div>
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label className="col-form-label text-start">
                              Comentarios:
                            </Label>
                            <Col xl="12" sm="9">
                              <Input
                                type="textarea"
                                id="comentarios"
                                className="form-control input-air-primary"
                                rows="2"
                                onChange={(e) => {
                                  setComentarios(e.target.value);
                                }}
                              />
                            </Col>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="4">
                    <div
                      className="project-box"
                      style={{ paddingBottom: "2px" }}
                    >
                      <span className="badge badge-primary">Opciones</span>
                      <div>
                        <div>
                          <FormGroup>
                            <div className="checkbox">
                              <Input
                                id="Control"
                                type="checkbox"
                                checked={control}
                                disabled={!opciones.includes("CONTROL")}
                                onChange={(event) => {
                                  setControl(event.target.checked);
                                }}
                              />
                              <Label for="Control">Control</Label>
                            </div>
                            <div className="checkbox">
                              <Input
                                id="Seguro"
                                type="checkbox"
                                checked={seguro}
                                disabled={!opciones.includes("SEGURO")}
                                onChange={(event) => {
                                  setSeguro(event.target.checked);
                                  setMontoSeguro(null);
                                }}
                              />
                              <Label for="Seguro">Seguro</Label>
                            </div>
                            {seguro && (
                              <FormGroup className="row">
                                {/* <Label className="col-form-label text-start">
                                  Monto:
                                </Label> */}
                                <Col xl="12" sm="12">
                                  <Select
                                    value={montoSeguro}
                                    options={seguros}
                                    isLoading={loadingSeguros}
                                    onChange={(seguro) =>
                                      setMontoSeguro(seguro)
                                    }
                                    placeholder={"Seleccione..."}
                                    required
                                  />
                                </Col>
                              </FormGroup>
                            )}
                            <div className="checkbox">
                              <Input
                                id="Noches"
                                type="checkbox"
                                checked={noches}
                                disabled={!opciones.includes("NOCHES")}
                                onChange={(event) => {
                                  setNoches(event.target.checked);
                                }}
                              />
                              <Label for="Noches">Fines de semana</Label>
                            </div>
                            <div className="checkbox">
                              <Input
                                id="NYF"
                                type="checkbox"
                                checked={nyf}
                                disabled={!opciones.includes("NYF")}
                                onChange={(event) => {
                                  setNYF(event.target.checked);
                                }}
                              />
                              <Label for="NYF">NYF</Label>
                            </div>
                            <div className="checkbox">
                              <Input
                                id="HBO"
                                type="checkbox"
                                checked={hbo}
                                disabled={!opciones.includes("HBO")}
                                onChange={(event) => {
                                  setHBO(event.target.checked);
                                }}
                              />
                              <Label for="HBO">HBO</Label>
                            </div>
                            {/*  <div className="checkbox">
                              <Input
                                id="Domicilio"
                                type="checkbox"
                                checked={domicilio}
                                disabled={!opciones.includes("ENVIO DOMICILIO")}
                                onChange={(event) => {
                                  setDomicilio(event.target.checked);
                                }}
                              />
                              <Label for="Domicilio">Domicilio</Label>
                            </div> */}
                            <div className="checkbox">
                              <Input
                                id="Pro-Bussines"
                                type="checkbox"
                                checked={proBussines}
                                disabled={
                                  !opciones.includes("PRO BUSSINES APPS")
                                }
                                onChange={(event) => {
                                  setProBussines(event.target.checked);
                                }}
                              />
                              <Label for="Pro-Bussines">Pro-Bussines</Label>
                            </div>
                          </FormGroup>
                          {metodosVenta.length > 0 && (
                            <FormGroup className="row">
                              <Label className="col-form-label text-start">
                                Método de venta:
                              </Label>
                              <Col xl="12" sm="12">
                                <Select
                                  value={metodoVenta}
                                  options={metodosVenta}
                                  onChange={(metodoVenta) =>
                                    setMetodoVenta(metodoVenta)
                                  }
                                  placeholder={"Seleccione..."}
                                  required
                                />
                              </Col>
                            </FormGroup>
                          )}
                          {showMultiRenta && (
                            <FormGroup className="row">
                              <Label className="col-form-label text-start">
                                Dto (%) Multi-Renta:
                              </Label>
                              <Col xl="12" sm="12">
                                <Input
                                  id="descuento"
                                  type="number"
                                  placeholder="Descuento (%)"
                                  onChange={(event) => {
                                    setMultirenta(event.target.value);
                                  }}
                                  required
                                />
                              </Col>
                            </FormGroup>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Fragment>
              ) : (
                <Col sm="12" md="12">
                  <div className="project-box" style={{ paddingBottom: "5px" }}>
                    <span className="badge badge-primary">Identificación</span>
                    <div>
                      <div>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label text-start">
                            Fecha:
                          </Label>
                          <Col xl="6" sm="9">
                            <DatePicker
                              className="form-control digits"
                              showPopperArrow={true}
                              selected={fecha}
                              dateFormat="dd/MM/yyyy"
                              locale="es"
                              onChange={(date) => {
                                setFecha(date);
                              }}
                              required
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label text-start">
                            ICC/SIM:
                          </Label>
                          <Col xl="6" sm="9">
                            <Input
                              value={co_id}
                              id="imei"
                              placeholder="IMEI..."
                              type="password"
                              onChange={(e) => {
                                setCo_Id(e.target.value);
                              }}
                              onInvalid={(e) => {
                                e.target.setCustomValidity(
                                  "Valor incorrecto. Revise los datos."
                                );
                              }}
                              onInput={(e) => {
                                e.target.setCustomValidity("");
                              }}
                              title=""
                              autoComplete={"new-password"}
                              minLength="20"
                              maxLength="20"
                              required
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label text-start">
                            ICC/SIM:
                          </Label>
                          <Col xl="6" sm="9">
                            <Input
                              value={co_id_confirm}
                              id="imei_confirm"
                              placeholder="Confirmar IMEI"
                              onChange={(e) => {
                                setCo_Id_Confirm(e.target.value);
                              }}
                              onInvalid={(e) => {
                                e.target.setCustomValidity(
                                  "Valor incorrecto. Revise los datos."
                                );
                              }}
                              onInput={(e) => {
                                e.target.setCustomValidity("");
                              }}
                              title=""
                              autoComplete={"new-password"}
                              minLength="20"
                              maxLength="20"
                              required
                            />
                            {co_id &&
                              co_id_confirm &&
                              (co_id === co_id_confirm ? (
                                <span style={{ color: "green" }}>
                                  Los imei coinciden
                                </span>
                              ) : (
                                <span style={{ color: "red" }}>
                                  Los imei no coinciden
                                </span>
                              ))}
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label text-start">
                            Producto:
                          </Label>

                          <Col xl="6" sm="9">
                            <Select
                              options={productos}
                              isLoading={loadingProductos}
                              onChange={(producto) => ActualizarDatos(producto)}
                              placeholder={"Seleccione..."}
                              required
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label text-start">
                            Recarga:
                          </Label>

                          <Col xl="6" sm="9">
                            <Select
                              value={plan}
                              options={planes}
                              placeholder={"Seleccione..."}
                              onChange={(plan) => {
                                setPlan(plan);
                                if (plan.label == "OTRO MONTO") {
                                  setShowMonto(true);
                                } else {
                                  setShowMonto(false);
                                  setMonto(plan.label);
                                }
                              }}
                              required
                            />
                          </Col>
                        </FormGroup>
                        {showMonto && (
                          <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label text-start">
                              Otro monto:
                            </Label>
                            <Col xl="6" sm="9">
                              <Input
                                value={monto}
                                id="otro_monto"
                                placeholder="$0.00"
                                type="number"
                                onChange={(e) => {
                                  setMonto(e.target.value);
                                }}
                                required
                              />
                            </Col>
                          </FormGroup>
                        )}
                        <FormGroup className="row">
                          <Label className="col-form-label text-start">
                            Comentarios:
                          </Label>
                          <Col xl="12" sm="9">
                            <Input
                              type="textarea"
                              id="comentarios"
                              className="form-control input-air-primary"
                              rows="2"
                              onChange={(e) => {
                                setComentarios(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Btn attrBtn={{ color: "secondary", onClick: toggle }}>
              Cancelar
            </Btn>
            <Btn
              attrBtn={{
                type: "submit",
                color: "primary",
                disabled: isSubmitDisabled,
              }}
            >
              {isSubmitDisabled ? "Guardando..." : "Guardar"}
            </Btn>
          </ModalFooter>
        </Form>
      </Modal>
    </CommonModal>
  );
};

export default SalesModalForm;
