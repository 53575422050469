import React, { useEffect, useState } from "react";
import DataTableComponent from "../Tables/DataTable/DataTableComponent";
import Request from "../../api/httpClient";
import { Row, Col } from "reactstrap";
const request = new Request();

const GerentesTab = ({ id_tipoVenta, id_familia, activeTab, tabId, fecha }) => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [addons, setAddons] = useState([]);
  const [loadingAddons, setLoadingAddons] = useState(false);
  const tableColumns = [
    {
      name: "Plan",
      with: 50,
      selector: (row) => `${row.marca}`,
      sortable: true,
      center: false,
    },
    ...(id_familia === 1
      ? [
          {
            name: (
              <div style={{ width: "100%", textAlign: "center" }}>Nuevo</div>
            ),
            minWidth: "50px",
            selector: (row) => `${row.nuevo}`,
            cell: (row) => (
              <div
                style={{ width: "50%", textAlign: "right" }}
              >{`${row.nuevo}`}</div>
            ),
            sortable: true,
            center: false,
          },
          {
            name: (
              <div style={{ width: "100%", textAlign: "center" }}>Propio</div>
            ),
            minWidth: "50px",
            selector: (row) => `${row.propio}`,
            cell: (row) => (
              <div
                style={{ width: "50%", textAlign: "right" }}
              >{`${row.propio}`}</div>
            ),
            sortable: true,
            center: false,
          },
        ]
      : [
          {
            name: (
              <div style={{ width: "100%", textAlign: "center" }}>6 Meses</div>
            ),
            minWidth: "50px",
            selector: (row) => `${row.ventas_6meses}`,
            cell: (row) => (
              <div
                style={{ width: "50%", textAlign: "right" }}
              >{`${row.ventas_6meses}`}</div>
            ),
            sortable: true,
            center: false,
          },
          {
            name: (
              <div style={{ width: "100%", textAlign: "center" }}>12 Meses</div>
            ),
            minWidth: "50px",
            selector: (row) => `${row.ventas_12meses}`,
            cell: (row) => (
              <div
                style={{ width: "50%", textAlign: "right" }}
              >{`${row.ventas_12meses}`}</div>
            ),
            sortable: true,
            center: false,
          },
          {
            name: (
              <div style={{ width: "100%", textAlign: "center" }}>18 Meses</div>
            ),
            minWidth: "50px",
            selector: (row) => `${row.ventas_18meses}`,
            cell: (row) => (
              <div
                style={{ width: "50%", textAlign: "right" }}
              >{`${row.ventas_18meses}`}</div>
            ),
            sortable: true,
            center: false,
          },
          {
            name: (
              <div style={{ width: "100%", textAlign: "center" }}>24 Meses</div>
            ),
            minWidth: "50px",
            selector: (row) => `${row.ventas_24meses}`,
            cell: (row) => (
              <div
                style={{ width: "50%", textAlign: "right" }}
              >{`${row.ventas_24meses}`}</div>
            ),
            sortable: true,
            center: false,
          },
        ]),
  ];

  const tableColumnsAddons = [
    {
      name: "Addons",
      minWidth: "100px",
      selector: (row) => `${row.Nombre}`,
      sortable: true,
      center: false,
    },
    {
      name: <div style={{ width: "100%", textAlign: "center" }}>UDS</div>,
      minWidth: "50px",
      selector: (row) => `${row.UDS}`,
      cell: (row) => (
        <div style={{ width: "50%", textAlign: "right" }}>{`${row.UDS}`}</div>
      ),
      sortable: true,
      center: false,
    },
  ];

  useEffect(() => {
    if (activeTab === tabId && data.length === 0) {
      getData();
      getAddons();
    }
  }, [activeTab]);

  useEffect(() => {
    getData();
    getAddons();
  }, [fecha]);

  const getData = async () => {
    setData([]);
    setLoadingData(true);
    let sucursalInfo = 0;

    if (JSON.parse(localStorage.sucursal_selec) !== null) {
      if (localStorage.getItem("SucursalesInfo") == 0) {
        sucursalInfo = +JSON.parse(localStorage.sucursal_selec).value;
      }
    }

    let data = {
      periodo: fecha,
      id_sucursal: sucursalInfo,
      id_tipoVenta: id_tipoVenta,
      id_familia: id_familia,
    };

    const response = await request.post("/gerentes/get/datos/", data);
    if (response && !response.error) {
      if (response.ventas && !response.empty) {
        // console.log(response.ventas);
        let ventas_formateadas;
        if (id_familia === 1) {
          ventas_formateadas = response.ventas.reduce((acumulador, venta) => {
            // Buscar si la marca ya existe en el acumulador
            const existente = acumulador.find(
              (item) => item.marca === venta.marca
            );

            if (existente) {
              // Si la marca ya existe, actualizar las ventas
              if (venta.tipo === "CON EQUIPO") {
                existente.nuevo += venta.ventas;
              } else if (venta.tipo === "SIN EQUIPO") {
                existente.propio += venta.ventas;
              }
            } else {
              // Si la marca no existe, agregarla al acumulador
              acumulador.push({
                marca: venta.marca,
                nuevo: venta.tipo === "CON EQUIPO" ? venta.ventas : 0,
                propio: venta.tipo === "SIN EQUIPO" ? venta.ventas : 0,
              });
            }

            return acumulador;
          }, []);
          let totalNuevo = 0;
          let totalPropio = 0;

          ventas_formateadas.forEach((venta) => {
            totalNuevo += venta.nuevo;
            totalPropio += venta.propio;
          });

          ventas_formateadas.push({
            marca: "TOTAL",
            nuevo: totalNuevo,
            propio: totalPropio,
          });
        } else {
          ventas_formateadas = response.ventas.reduce((acumulador, venta) => {
            // Buscar si la marca ya existe en el acumulador
            const existente = acumulador.find(
              (item) => item.marca === venta.marca
            );

            if (existente) {
              // Si la marca ya existe, sumar las ventas
              existente.ventas_6meses += venta.ventas_6meses;
              existente.ventas_12meses += venta.ventas_12meses;
              existente.ventas_18meses += venta.ventas_18meses;
              existente.ventas_24meses += venta.ventas_24meses;
            } else {
              // Si la marca no existe, agregarla al acumulador
              acumulador.push({
                marca: venta.marca,
                ventas_6meses: venta.ventas_6meses,
                ventas_12meses: venta.ventas_12meses,
                ventas_18meses: venta.ventas_18meses,
                ventas_24meses: venta.ventas_24meses,
              });
            }

            return acumulador;
          }, []);
          let TotalVentas_6meses = 0;
          let TotalVentas_12meses = 0;
          let TotalVentas_18meses = 0;
          let TotalVentas_24meses = 0;

          ventas_formateadas.forEach((venta) => {
            TotalVentas_6meses += venta.ventas_6meses;
            TotalVentas_12meses += venta.ventas_12meses;
            TotalVentas_18meses += venta.ventas_18meses;
            TotalVentas_24meses += venta.ventas_24meses;
          });

          ventas_formateadas.push({
            marca: "TOTAL",
            ventas_6meses: TotalVentas_6meses,
            ventas_12meses: TotalVentas_12meses,
            ventas_18meses: TotalVentas_18meses,
            ventas_24meses: TotalVentas_24meses,
          });
        }
        setData(ventas_formateadas);
      } else {
        setData([]);
        setLoadingData(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingData(false);
  };

  const getAddons = async () => {
    setAddons([]);
    setLoadingAddons(true);
    let sucursalInfo = 0;

    if (JSON.parse(localStorage.sucursal_selec) !== null) {
      if (localStorage.getItem("SucursalesInfo") == 0) {
        sucursalInfo = +JSON.parse(localStorage.sucursal_selec).value;
      }
    }
    let data = {
      periodo: fecha,
      id_sucursal: sucursalInfo,
      id_tipoVenta: id_tipoVenta,
    };
    //console.log(data);
    const response = await request.post("/gerentes/get/addons/", data);
    if (response && !response.error) {
      //console.log(response.addon);
      if (response.addons && !response.empty) {
        // Transformar los datos
        const addonsFormateados = response.addons.flatMap((addon) => {
          let { Control, Noches, Seguros } = addon;

          // Comprobar si Control, Noches o Seguros son null
          Control = Control || 0;
          Noches = Noches || 0;
          Seguros = Seguros || "";

          const seguros = Seguros.split(", ");

          // Crear un objeto para Control y Noches
          const addons = [
            { Nombre: "Control", UDS: Control },
            { Nombre: "Noches", UDS: Noches },
          ];

          // Crear un objeto para cada seguro
          seguros.forEach((seguro) => {
            const [nombre, ups] = seguro.split(" - ");
            addons.push({
              Nombre: nombre || "Seguros",
              UDS: parseInt(ups, 10) || 0,
            });
          });

          return addons;
        });

        // Calcular la suma de todos los valores de la columna "UDS"
        const totalUDS = addonsFormateados.reduce((total, addon) => {
          return total + addon.UDS;
        }, 0);

        // Agregar una fila "Total" al final de las columnas
        addonsFormateados.push({ Nombre: "TOTAL", UDS: totalUDS });
        setAddons(addonsFormateados);
      } else {
        setAddons([]);
        setLoadingAddons(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingAddons(false);
  };

  return (
    <Row className="mt-4">
      <Col sm={8}>
        <DataTableComponent
          tableColumns={tableColumns}
          tableRows={data}
          loadingVentas={loadingData}
          pagination={false}
        />
      </Col>
      {id_familia === 1 && (
        <Col sm={4}>
          <DataTableComponent
            tableColumns={tableColumnsAddons}
            tableRows={addons}
            loadingVentas={loadingAddons}
            pagination={false}
          />
        </Col>
      )}
    </Row>
  );
};

export default GerentesTab;
